import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1dcc2f3a = () => interopDefault(import('../modules/checkout/pages/Cart.vue' /* webpackChunkName: "" */))
const _128eed60 = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _438b16ac = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _3b8b2e92 = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _6d91f54d = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _571fc0f4 = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _0f4fbfda = () => interopDefault(import('../modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _2e9ab8e8 = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/Cms" */))
const _6f303901 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _769cad6f = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _27abe2d6 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _6c97982b = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _e4ac0822 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _26ca7aa1 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _6991faa2 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _5e4d9aaa = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _2633717b = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _9f806282 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _a68d8638 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _72cefed9 = () => interopDefault(import('../pages/Faq.vue' /* webpackChunkName: "pages/Faq" */))
const _d1052da8 = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "" */))
const _309b0248 = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cart",
    component: _1dcc2f3a,
    name: "cart___default"
  }, {
    path: "/checkout",
    component: _128eed60,
    name: "checkout___default",
    children: [{
      path: "billing",
      component: _438b16ac,
      name: "billing___default"
    }, {
      path: "payment",
      component: _3b8b2e92,
      name: "payment___default"
    }, {
      path: "shipping",
      component: _6d91f54d,
      name: "shipping___default"
    }, {
      path: "thank-you",
      component: _571fc0f4,
      name: "thank-you___default"
    }, {
      path: "user-account",
      component: _0f4fbfda,
      name: "user-account___default"
    }]
  }, {
    path: "/Cms",
    component: _2e9ab8e8,
    name: "Cms___default"
  }, {
    path: "/customer",
    component: _6f303901,
    meta: {"titleLabel":"My Account"},
    name: "customer___default",
    children: [{
      path: "addresses-details",
      component: _769cad6f,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___default"
    }, {
      path: "my-newsletter",
      component: _27abe2d6,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___default"
    }, {
      path: "my-profile",
      component: _6c97982b,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___default"
    }, {
      path: "my-reviews",
      component: _e4ac0822,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___default"
    }, {
      path: "my-wishlist",
      component: _26ca7aa1,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___default"
    }, {
      path: "order-history",
      component: _6991faa2,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___default"
    }, {
      path: "/reset-password",
      component: _5e4d9aaa,
      alias: "/customer/account/createPassword",
      name: "reset-password___default"
    }, {
      path: "addresses-details/create",
      component: _2633717b,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___default"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _9f806282,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___default"
    }, {
      path: "order-history/:orderId",
      component: _a68d8638,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___default"
    }]
  }, {
    path: "/Faq",
    component: _72cefed9,
    name: "Faq___default"
  }, {
    path: "/german",
    component: _d1052da8,
    name: "home___german"
  }, {
    path: "/Home",
    component: _d1052da8,
    name: "Home___default"
  }, {
    path: "/Page",
    component: _309b0248,
    name: "Page___default"
  }, {
    path: "/german/cart",
    component: _1dcc2f3a,
    name: "cart___german"
  }, {
    path: "/german/checkout",
    component: _128eed60,
    name: "checkout___german",
    children: [{
      path: "billing",
      component: _438b16ac,
      name: "billing___german"
    }, {
      path: "payment",
      component: _3b8b2e92,
      name: "payment___german"
    }, {
      path: "shipping",
      component: _6d91f54d,
      name: "shipping___german"
    }, {
      path: "thank-you",
      component: _571fc0f4,
      name: "thank-you___german"
    }, {
      path: "user-account",
      component: _0f4fbfda,
      name: "user-account___german"
    }]
  }, {
    path: "/german/Cms",
    component: _2e9ab8e8,
    name: "Cms___german"
  }, {
    path: "/german/customer",
    component: _6f303901,
    meta: {"titleLabel":"My Account"},
    name: "customer___german",
    children: [{
      path: "addresses-details",
      component: _769cad6f,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___german"
    }, {
      path: "my-newsletter",
      component: _27abe2d6,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___german"
    }, {
      path: "my-profile",
      component: _6c97982b,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___german"
    }, {
      path: "my-reviews",
      component: _e4ac0822,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___german"
    }, {
      path: "my-wishlist",
      component: _26ca7aa1,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___german"
    }, {
      path: "order-history",
      component: _6991faa2,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___german"
    }, {
      path: "addresses-details/create",
      component: _2633717b,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___german"
    }, {
      path: "/german/reset-password",
      component: _5e4d9aaa,
      alias: "/customer/account/createPassword",
      name: "reset-password___german"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _9f806282,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___german"
    }, {
      path: "order-history/:orderId",
      component: _a68d8638,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___german"
    }]
  }, {
    path: "/german/Faq",
    component: _72cefed9,
    name: "Faq___german"
  }, {
    path: "/german/Home",
    component: _d1052da8,
    name: "Home___german"
  }, {
    path: "/german/Page",
    component: _309b0248,
    name: "Page___german"
  }, {
    path: "/german/:slug+",
    component: _309b0248,
    name: "page___german"
  }, {
    path: "/",
    component: _d1052da8,
    name: "home___default"
  }, {
    path: "/:slug+",
    component: _309b0248,
    name: "page___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
